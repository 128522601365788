<template>
    <div class="summary-tile">
        <hr class="summary-tile__top-bar" :class="order._warningColor">

        <div class="summary-tile__content">
            <p class="summary-tile__number">
                <span>#{{ order.orderDetails.orderNumberRaw }}</span>
                <span>{{ order._customerName }}</span>
            </p>
            <p class="summary-tile__categories" v-if="order.pies || order.extras">
                <span v-if="order.pies">{{ order.pies.length }} pies</span>
                <span v-if="order.extras">{{ order.extras.length }} extra</span>
            </p>
            <hr>
            <p class="summary-tile__categories" v-if="order._pickupDisplay">
                <strong>Pickup: {{ order._pickupDisplay }}</strong>
                <template v-if="order._startTimeDisplay">
                    <br>
                    <strong>Start Time: {{ order._startTimeDisplay }}</strong>
                </template>
            </p>
        </div>
        <HCbutton class="summary-tile__cta" :class="order._warningColor" @click="startOrder">Start</HCbutton>
        <HCbutton class="summary-tile__cta summary-tile__cta--chit" v-if="enableChitPrinting" :class="order._warningColor" @click="printChits">Print Chits</HCbutton>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import HCbutton from '@/components/HCbutton/HCbutton.vue';
import { kdsApi } from '@/services/api';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components:{ HCbutton },
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        }
    },
    setup() {
        const store = inject('store') as Store;
        return {enableChitPrinting: computed(()=>store.state.enableChitPrinting)}
    },
    methods:{
        startOrder() {
            kdsApi.bumpStart(this.order.orderDetails.orderID);
        },
        printChits() {
            kdsApi.printChits(this.order.orderDetails.orderID);
        }
    },
});
</script>

<style scoped>
.summary-tile {
    min-width: 226px;
    height: 252px;
    background-color: var(--color-white);
    margin-right: 24px;
    padding-bottom: 14px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.summary-tile__top-bar {
    height: 8px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 16px;
    border: none;
    flex-shrink: 1;
}
.summary-tile__content {
    padding: 0 18px;
    flex-grow: 1;
}

.summary-tile__number {
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
}

.summary-tile__categories {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
}

.summary-tile__categories span {
    display: block;
}

.summary-tile__cta {
    width: 192px;
    margin-top: 8px;
}

.summary-tile__cta.tile--warning {
    color: var(--color-black);
}

.summary-tile__cta--chit {
    opacity: 0.7;
}
</style>
