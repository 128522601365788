
import { computed, defineComponent, inject } from "vue";
import HCbutton from '@/components/HCbutton/HCbutton.vue';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components: {HCbutton},
    props: {
        type: String,
        color: String
    },
    emits: [ 'itemBump', 'itemDone', 'itemChit'],
    setup() {
        const store = inject('store') as Store;
        return {
            enableChitPrinting: computed(() => store.state.enableChitPrinting)
        };
    },
});
