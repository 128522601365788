<template>
    <div class="details-tile" v-if="pie && showPie(pie)">
        <hr class="details-tile__top-bar" :class="warningColor">
        <div class="details-tile__chit-printed" v-if="pie?.chitPrinted">Printed</div>
        <Heading
            :name="pie.name"
            :pieOfOrder="pie.pieOfOrder"
            :itemsTotal="itemsTotal"
            :orderNumberRaw="orderNumberRaw"
            :pickupTime="pickupTime"
            :startTime="startTime"
            :customer="customerName"
        />
        <SpecialInstruction v-if="pie.comment" :comment="pie.comment" />
        <Ingredients v-if="pie.ingredients" :ingredients="pie.ingredients" :type="type"/>
        <Ctas
            :color="warningColor"
            :type="type"
            @item-bump="handleItemBump(pie)"
            @item-chit="handleItemChitPrint(pie)"
            @item-done="handleItemDone(pie)"
        />
    </div>
    <div class="details-tile" v-if="extras && extras.length > 0">
        <hr class="details-tile__top-bar" :class="warningColor">
        <Heading
            name="Extras"
            :pieOfOrder="itemsTotal"
            :itemsTotal="itemsTotal"
            :orderNumberRaw="orderNumberRaw"
            :customer="customerName"
        />
        <ul class="details-tile__extras">
            <li class="details-tile__extra" v-for="extra of extras" :key="extra.order_item_id+extra.qtyOfItem">
                {{ extra.quantity }} {{ extra.name }} {{ modifiers(extra.modifiers) }}
            </li>
        </ul>
        <Ctas
            :type="type"
            :color="warningColor"
            @item-bump="handleExtrasBump(pie)"
            @item-chit="handleExtraChitPrint(pie)"
            @item-done="handleExtraDone(pie)"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SpecialInstruction from './components/SpecialInstruction.vue';
import Ingredients from "@/components/Ingredients/Ingredients.vue";
import Heading from './components/Heading.vue';
import Ctas from './components/Ctas.vue';
import { kdsApi, webApi } from '@/services/api';

export default defineComponent({
    components: { SpecialInstruction, Ingredients, Heading, Ctas },
    props: {
        orderID: {
            required: true,
            type: Number,
        },
        itemsTotal: {
            required: true,
            type: Number,
        },
        type: {
            required: true,
            type: String
        },
        customerName: {
            required: true,
            type: String
        },
        warningColor: {
            required: true,
            type: String
        },
        orderNumberRaw: {
            required: true,
            type: String
        },
        pickupTime: {
            required: true,
            type: String
        },
        startTime: {
            required: true,
            type: String
        },
        pie: Object as () => FBorderI.PostBakeOrderPieI,
        extras: Array as () => FBorderI.OrderExtraI[],
    },
    methods: {
        showPie(pie: FBorderI.OrderPieI){
            return pie.kds_state == this.$route.params.station || this.$route.params.station === 'all';
        },
        handleItemBump(pie: FBorderI.OrderPieI) {
            if (this.$route.params.station === 'all') {
                webApi.setPieKdsState(this.orderID, pie.order_item_id, pie.qtyOfItem);
            } else {
                webApi.setPieKdsState(this.orderID, pie.order_item_id, pie.qtyOfItem, 'next');
            }
        },
        handleItemChitPrint(pie: FBorderI.OrderPieI) {
            kdsApi.printChit({
                orderID: this.orderID,
                pie: pie.pieOfOrder,
            });
        },
        handleItemDone(pie: FBorderI.OrderPieI) {
            kdsApi.printLabel({
                orderID: this.orderID,
                pie: pie.pieOfOrder,
            });
        },
        handleExtrasBump() {
            if (!this.extras) return;
            webApi.setExtraKdsState(this.extras, this.orderID);
        },
        handleExtraDone() {
            kdsApi.printLabel({
                orderID: this.orderID,
                extras: true
            });
        },
        handleExtraChitPrint() {

        },
        modifiers(modifiers: FBorderI.OrderExtraModifierI[]) {
            if (modifiers) {
                return modifiers.map(modifier => modifier.description).join(' ')
            }
            return ''
        },
    },
});
</script>

<style scoped>
.content_padding {
    padding-left: 18px;
    padding-right: 15px;
}
.details-tile {
    width: 309px;
    min-width: 309px;
    background-color: var(--color-white);
    margin-right: 25px;
    padding-bottom: 22px;
}

.details-tile__top-bar {
    height: 8px;
    margin-top: 0;
    margin-block-end: 0;
    border: none;
    flex-shrink: 1;
    background-color: var(--color-white);
}

.details-tile__chit-printed {
    background-color: var(--color-gorse);
    font-weight: var(--weight-bold);
    padding: 6px;
    text-transform: uppercase;
    text-align: center;
}

.details-tile__heading {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 6px 18px;
}
.details-tile__pie-name {
    font-weight: var(--weight-bolder);
    font-size: 22px;
    line-height: 26px;
}

.details-tile__pie-of-order {
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19px;
    opacity: 0.5;
}
.details-tile__ref {
    font-weight: var(--weight-bold);
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
}

.details-tile__extras {
    padding: 0;
    margin: 16px 0px 27px;
    background-color: var(--color-gorse);
    font-weight: var(--weight-bold);
}
.details-tile__extra {
    list-style-type: none;
    min-height: 33px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 5px;
    font-size: 18px;
    line-height: 170%;
    opacity: 0.5;
}
</style>
