
import { computed, defineComponent, inject } from "vue";
import HCbutton from '@/components/HCbutton/HCbutton.vue';
import { kdsApi } from '@/services/api';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components:{ HCbutton },
    props: {
        order: {
            type: Object as () => FBorderI.OrderI,
            required: true,
        }
    },
    setup() {
        const store = inject('store') as Store;
        return {enableChitPrinting: computed(()=>store.state.enableChitPrinting)}
    },
    methods:{
        startOrder() {
            kdsApi.bumpStart(this.order.orderDetails.orderID);
        },
        printChits() {
            kdsApi.printChits(this.order.orderDetails.orderID);
        }
    },
});
