<template>
    <div class="details-tile__heading content_padding">
        <div class="details-tile__pie-name">{{ name }}</div>
        <div class="details-tile__pie-of-order">{{ pieOfOrder }}/{{ itemsTotal }}</div>
    </div>
    <div class="details-tile__ref content_padding">
        <span>#{{ orderNumberRaw }}</span>
        <span v-if="customer">{{ customer }}</span>
    </div>
    <strong class="content_padding" v-if="pickupTime">Pickup: {{ pickupTime }}</strong>
    <template v-if="startTime">
        <br>
        <strong class="content_padding" >Start Time: {{ startTime }}</strong>
    </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        name: String,
        pieOfOrder: Number,
        itemsTotal: Number,
        orderNumberRaw: String,
        pickupTime: String,
        startTime: String,
        customer: String,
    },
});
</script>

<style scoped>
.content_padding {
    padding-left: 18px;
    padding-right: 15px;
}
.details-tile__heading {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    padding: 0 15px 6px 18px;
}
.details-tile__pie-name {
    font-weight: var(--weight-bolder);
    font-size: 22px;
    line-height: 26px;
}

.details-tile__pie-of-order {
    font-weight: var(--weight-bold);
    font-size: 16px;
    line-height: 19px;
    opacity: 0.5;
}
.details-tile__ref {
    font-weight: var(--weight-bold);
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}
</style>
