
import { defineComponent } from "vue";

export default defineComponent({
    data: ()=>({
        show_instructions: false,
    }),
    props: {
        comment: String,
    },
    methods: {
        toggle_visibility() {
            this.show_instructions = !this.show_instructions;
        }
    }
});
