<template>
<div class="no-orders">
    <p class="no-orders__heading font-druk">NO ORDERS YET</p>
    <p class="no-orders__blurb">Hang tight, THEY’LL BE HERE BEFORE YOU KNOW IT</p>
</div>
</template>

<script>
export default {

}
</script>

<style>
.no-orders {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.no-orders__heading {
    font-weight: var(--weight-bolder);
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 8px;
}
.no-orders__blurb {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
}
</style>
