
import { defineComponent } from "vue";
import SpecialInstruction from './components/SpecialInstruction.vue';
import Ingredients from "@/components/Ingredients/Ingredients.vue";
import Heading from './components/Heading.vue';
import Ctas from './components/Ctas.vue';
import { kdsApi, webApi } from '@/services/api';

export default defineComponent({
    components: { SpecialInstruction, Ingredients, Heading, Ctas },
    props: {
        orderID: {
            required: true,
            type: Number,
        },
        itemsTotal: {
            required: true,
            type: Number,
        },
        type: {
            required: true,
            type: String
        },
        customerName: {
            required: true,
            type: String
        },
        warningColor: {
            required: true,
            type: String
        },
        orderNumberRaw: {
            required: true,
            type: String
        },
        pickupTime: {
            required: true,
            type: String
        },
        startTime: {
            required: true,
            type: String
        },
        pie: Object as () => FBorderI.PostBakeOrderPieI,
        extras: Array as () => FBorderI.OrderExtraI[],
    },
    methods: {
        showPie(pie: FBorderI.OrderPieI){
            return pie.kds_state == this.$route.params.station || this.$route.params.station === 'all';
        },
        handleItemBump(pie: FBorderI.OrderPieI) {
            if (this.$route.params.station === 'all') {
                webApi.setPieKdsState(this.orderID, pie.order_item_id, pie.qtyOfItem);
            } else {
                webApi.setPieKdsState(this.orderID, pie.order_item_id, pie.qtyOfItem, 'next');
            }
        },
        handleItemChitPrint(pie: FBorderI.OrderPieI) {
            kdsApi.printChit({
                orderID: this.orderID,
                pie: pie.pieOfOrder,
            });
        },
        handleItemDone(pie: FBorderI.OrderPieI) {
            kdsApi.printLabel({
                orderID: this.orderID,
                pie: pie.pieOfOrder,
            });
        },
        handleExtrasBump() {
            if (!this.extras) return;
            webApi.setExtraKdsState(this.extras, this.orderID);
        },
        handleExtraDone() {
            kdsApi.printLabel({
                orderID: this.orderID,
                extras: true
            });
        },
        handleExtraChitPrint() {

        },
        modifiers(modifiers: FBorderI.OrderExtraModifierI[]) {
            if (modifiers) {
                return modifiers.map(modifier => modifier.description).join(' ')
            }
            return ''
        },
    },
});
