
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        name: String,
        pieOfOrder: Number,
        itemsTotal: Number,
        orderNumberRaw: String,
        pickupTime: String,
        startTime: String,
        customer: String,
    },
});
