<template>
    <div class="orders-list-body">
        <template v-for="item of inProgress" :key="item">
            <ProductDetailsTile
                :pie="item"
                type="pre_bake"
                :orderID="item?.orderID"
                :itemsTotal="item?.totalPies"
                :customerName="item?._customerName"
                :warningColor="item?._warningColor"
                :orderNumberRaw="item?.orderNumberRaw"
                :pickupTime="item?._pickupDisplay"
                :startTime="item?._startTimeDisplay"
            />
        </template>
        <template v-if="queued && showQueue">
            <ProductSummaryTile
                v-for="order of queued"
                :key="order.orderDetails.orderID"
                :class="inProgress.length && 'fade-order'"
                :order="order"
            />
        </template>
        <NoOrders v-if="!inProgress.length && !queued.length"/>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { Store } from '@/stores'; // eslint-disable-line
import { orders } from '@/services/firebase';
import NoOrders from '@/components/NoOrders/NoOrders.vue';
import ProductDetailsTile from '@/components/ProductDetailsTile/ProductDetailsTile.vue';
import ProductSummaryTile from '@/components/ProductSummaryTile/ProductSummaryTile.vue';
import { useRoute } from 'vue-router';


export default defineComponent({
    name: 'OrderCompleted',
    components: { ProductDetailsTile, ProductSummaryTile, NoOrders },
    setup() {
        const store = inject('store') as Store;
        const shouldShowQueue = (station: string)=> store.state.allOrderStationsPreBake.includes(station)
        const showQueue = ref(false);
        const route = useRoute();

        watch(() => store.state.preStations, () =>showQueue.value = shouldShowQueue(route.params.station as string));
        watch(() => route.params, newParams => showQueue.value = shouldShowQueue(newParams.station as string));

        return {
            inProgress: computed(()=>orders?.preBaked?.inProgress),
            queued: computed(()=>orders?.preBaked?.queued),
            allOrderStationsPreBake: store.state.allOrderStationsPreBake,
            showQueue
        };
    },
    mounted() {
        this.showQueue = this.allOrderStationsPreBake.includes(this.$route.params.station as string);
    }
});
</script>
<style scoped>
.fade-order {
    opacity: 0.7;
}
</style>
