<template>
    <div class="special-inst">
        <button class="special-inst__header" @click="toggle_visibility">
            <div>SPECIAL INSTRUCTIONS</div>
            <div>
                <img src="@/assets/carrot-black.svg" class="special-inst__img" :class="show_instructions && 'inst_opened'" alt="Toggle Special Instructions">
            </div>
        </button>
        <div class="special-inst__content" v-show="show_instructions">
            {{ comment }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    data: ()=>({
        show_instructions: false,
    }),
    props: {
        comment: String,
    },
    methods: {
        toggle_visibility() {
            this.show_instructions = !this.show_instructions;
        }
    }
});
</script>

<style scoped>
.special-inst__header {
    background-color: var(--color-red-80);
    display: flex;
    width: 100%;
    border: none;
    outline: none;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14px;
    padding-left: 21px;
    padding-right: 15px;
}

.special-inst__img {
    transform: rotate(180deg);
}

.special-inst__img.inst_opened {
    transform: rotate(0);
}

.special-inst__content {
    background-color: var(--color-red-50);
    padding: 9px 21px 7px 21px;
    font-weight: var(--weight-normal);
    font-size: 12px;
    line-height: 14px;
}
</style>
