<template>
    <template v-if="type == 'pre_bake'">
        <HCbutton class="details-tile__cta" :class="color" @click="$emit('item-bump')">bump</HCbutton>
        <HCbutton class="details-tile__cta details-tile__cta--chit" v-if="enableChitPrinting" :class="color" @click="$emit('item-chit')">Print Chit</HCbutton>
    </template>

    <template v-if="type == 'post_bake'">
        <HCbutton class="details-tile__cta" :class="color" @click="$emit('item-done')">Done</HCbutton>
    </template>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import HCbutton from '@/components/HCbutton/HCbutton.vue';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components: {HCbutton},
    props: {
        type: String,
        color: String
    },
    emits: [ 'itemBump', 'itemDone', 'itemChit'],
    setup() {
        const store = inject('store') as Store;
        return {
            enableChitPrinting: computed(() => store.state.enableChitPrinting)
        };
    },
});
</script>

<style scoped>
.details-tile__cta {
    width: 277px;
    margin-top: 12px;
}
.details-tile__cta--chit {
    opacity: 0.7;
}

.details-tile__cta.tile--warning {
    color: var(--color-black);
}
</style>
